import { ReactElement } from "react";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import { ENTITY_TYPES } from "~/lib/data/entityTypes";

export function isEmpty(str: string): boolean {
  return !str || str.length === 0;
}

export function objIsEmpty(obj: Record<string, unknown>): boolean {
  return obj && Object.keys(obj).length === 0;
}

export const checkAllFieldsGiven = <
  T extends Record<string, string | SelectChoice[] | boolean | number>
>(
  values: T
): Record<string, string> => {
  const errors: Record<string, string> = {};
  for (const [key, value] of Object.entries(values)) {
    if (!value) {
      errors[key] = "Required";
    } else if (typeof value === "string") {
      if (isEmpty(value)) {
        errors[key] = "Required";
      }
    } else if (typeof value === "number") {
      if (Number(value) === 0) {
        errors[key] = "Required";
      }
    } else if (Array.isArray(value)) {
      if (value.length < 1) {
        errors[key] = "Required";
      }
    } else if (typeof value === "boolean") {
      if (!value) {
        errors[key] = "Required";
      }
    } else {
      throw Error(
        `Unsupported form data type being checked for key \"${key}\" and value \'${value}\'`
      );
    }
  }
  return errors;
};

export const getEntityTypeDisplay = (
  id: string | undefined | null
): string | null => {
  if (!id) return null;
  const results = ENTITY_TYPES.filter((e) => e.id === id);
  return results.length > 0 ? results[0].text.toString() : null;
};

export const getSelectChoiceArrayById = (
  id: string | string[],
  choices: SelectChoice[]
): SelectChoice[] => {
  if (Array.isArray(id)) {
    return choices.filter((c) => id.includes(c.id));
  } else {
    return choices.filter((e) => e.id === id);
  }
};

export const getSelectChoiceById = <T = string | ReactElement>(
  id: string,
  choices: SelectChoice<T>[]
): SelectChoice<T> | undefined => {
  const results = choices.filter((e) => e.id === id);
  return results.length > 0 ? results[0] : undefined;
};

export const getSelectChoiceIds = (choices?: SelectChoice[]): string[] => {
  if (!choices) return [];

  return choices.map(({ id }) => id);
};

export const getStringFromSelectedChoice = (
  selectChoices: SelectChoice[] | undefined
): string => {
  if (selectChoices && selectChoices.length > 0) {
    return selectChoices[0].id;
  }
  return "";
};

/*
 * Sorts an array of SelectChoice[]
 */
export const sortAlphabetically = (choices: SelectChoice[]): SelectChoice[] =>
  choices.slice().sort(function (a, b) {
    if (typeof a.text === "string" && typeof b.text === "string") {
      return a.text.localeCompare(b.text);
    }
    return 1;
  });

export const getLocalUsDateString = (date: Date): string =>
  date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export const getUsDateString = (date: Date): string =>
  date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

// When getting a date from the database, use this to ensure correct local display
export const parseLocalDate = (dateStr: string): Date => {
  const date = new Date(dateStr);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
};

export const getNumber = (string: string): number | undefined => {
  const val = Number(string);
  if (isNaN(val)) {
    return undefined;
  }

  return val;
};

export function assertNeverHit(_: never) {
  return;
}
