import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import cntl from "cntl";
import React, { Fragment } from "react";
import { ChevronDown } from "../assets/icons/chevron-down";
import { ChevronUp } from "../assets/icons/chevron-up";
import { SelectChoice } from "../forms/fields/SelectItem";

interface FilterProps {
  filterText: string;
  choices: SelectChoice[];
  selectedChoice?: SelectChoice;
  onChoiceSelected: (choice?: SelectChoice) => void;
  preventDeselect?: boolean;
}

const Filter: React.FC<FilterProps> = ({
  filterText,
  choices,
  selectedChoice,
  onChoiceSelected,
  preventDeselect,
}) => {
  return (
    <Listbox value={selectedChoice} onChange={onChoiceSelected}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={cntl`
            relative
              block
              rounded-md
              pl-4
              text-darkBlue
              ${
                selectedChoice === undefined
                  ? "border border-disabledGrey bg-white"
                  : "border border-petrolGreen bg-lightGreen"
              }
              
              cursor-pointer
              border
              border-borderGrey
              sm:text-sm`}
          >
            <div className="flex h-10 items-stretch gap-2">
              <span className="flex items-center">
                {selectedChoice !== undefined ? (
                  <div className="flex items-center">
                    <span className="text-darkGreen">
                      {selectedChoice.text}
                    </span>
                  </div>
                ) : (
                  <span>{filterText}</span>
                )}
              </span>

              <div className="flex items-center">
                {selectedChoice === undefined ? (
                  <div className="mt-0.5 flex items-center pr-4">
                    {open ? <ChevronDown /> : <ChevronUp />}
                  </div>
                ) : (
                  <div>
                    {preventDeselect ? (
                      <div className="mt-0.5 flex items-center pr-4">
                        {open ? <ChevronDown /> : <ChevronUp />}
                      </div>
                    ) : (
                      <div
                        className="group flex h-full items-center rounded-r-md pl-4 pr-4 transition-colors duration-500 hover:bg-[#d4e5d4] hover:ease-in-out"
                        onClick={(e) => {
                          e.preventDefault();
                          onChoiceSelected(undefined);
                        }}
                      >
                        <XMarkIcon className="h-4 w-4 text-darkGreen group-hover:text-petrolGreen" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              as="div"
              className={
                "absolute z-30 mt-1 max-h-60 overflow-auto rounded-md border border-disabledGrey bg-white p-2 text-base shadow-lg sm:text-sm"
              }
            >
              {choices.map((selectedChoice) => (
                <Listbox.Option
                  className={"ml-0 cursor-pointer list-none"}
                  key={selectedChoice.id}
                  value={selectedChoice}
                >
                  {({ selected, active }) => (
                    <div
                      className={`ml-0 flex gap-2 rounded-md px-2 py-1 hover:bg-lightGreen hover:text-darkGreen ${
                        selected ? "bg-lightGreen text-darkGreen" : null
                      } ${active ? "bg-lightGreen text-darkGreen" : null}`}
                    >
                      <span
                        key={selectedChoice.id}
                        className={`
                          flex items-center pl-1 ${
                            selected ? "text-darkGreen" : "text-transparent"
                          }`}
                      >
                        <CheckIcon
                          key={selectedChoice.id}
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </span>
                      <span>{selectedChoice.text}</span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default Filter;
