import { useRouter } from "next/router";
import React, { useState } from "react";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import withOnboardingRequired from "~/components/modal/modals/withOnboardingRequired";
import { DesktopLeftNavBar } from "../DesktopLeftNavBar";
import Footer from "../Footer";
import Header from "../Header";
import { MobileLeftNavBar } from "../MobileLeftNavBar";
import { FUNDING_NAVIGATION_ITEMS } from "./navigationItems";
import { MobileProgramsSidebar } from "./programs/MobileProgramsSidebar";
import { ProgramsFilterStateContext } from "./programs/ProgramsFilterStateContext";
import { ProgramsSidebar } from "./programs/ProgramsSidebar";
import { ProgramsSidebarContext } from "./programs/ProgramsSidebarContextType";
import { FilterType } from "./programs/shared";

interface DashboardLayoutProps {
  children?: React.ReactNode;
  useProgramsSidebar?: boolean;
  filterQueryValues?: ProgramsListQuery[];
}

export type ProgramsListQuery = Record<FilterType, string | null>;

export type FilterStateType = Record<FilterType, SelectChoice["id"][]>;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  useProgramsSidebar = false,
  filterQueryValues,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [programsSidebarOpen, setProgramsSidebarOpen] = useState(false);

  const [filterState, setFilterState] = useState<FilterStateType | undefined>(
    filterQueryValues && useProgramsSidebar
      ? filterQueryValues.reduce((acc, val) => {
          const entries = Object.entries<string | null>(val);
          if (entries.length !== 1) {
            throw Error(
              "Got FilterStateType record object with more or less than one entry"
            );
          }
          const [key, value] = entries[0];
          return {
            ...acc,
            [key]: getFilterStateFromQuery(value),
          };
        }, {} as FilterStateType)
      : undefined
  );

  const router = useRouter();

  const currentNavItem = FUNDING_NAVIGATION_ITEMS.flat().find(
    (nav) => nav.href === router.pathname
  );

  return (
    <div className="font-body">
      <ProgramsFilterStateContext.Provider
        value={{ filterState, setFilterState }}
      >
        <MobileLeftNavBar
          {...{
            sidebarOpen,
            setSidebarOpen,
            currentNavItem,
            useProgramsSidebar,
            navigationItems: FUNDING_NAVIGATION_ITEMS,
          }}
        />
        {programsSidebarOpen && (
          <MobileProgramsSidebar
            {...{ programsSidebarOpen, setProgramsSidebarOpen }}
          />
        )}

        <div className="flex h-screen flex-1 flex-col">
          <Header
            setSidebarOpen={setSidebarOpen}
            useProgramsSidebar={useProgramsSidebar}
            selectedPage="funding"
          />
          {/* Static sidebar for desktop */}
          <div>
            <div
              className={`fixed hidden md:inset-y-0 ${
                useProgramsSidebar ? "lg:flex" : "md:flex"
              } md:w-72 md:flex-col md:pt-16`}
            >
              <DesktopLeftNavBar
                currentNavItem={currentNavItem}
                navigationItems={FUNDING_NAVIGATION_ITEMS}
              />
            </div>
            {useProgramsSidebar && (
              <ProgramsSidebarContext.Provider
                value={{
                  sidebarOpen: programsSidebarOpen,
                  setSidebarOpen: setProgramsSidebarOpen,
                }}
              >
                <div
                  className={`fixed hidden md:inset-y-0 md:left-0 md:flex md:w-72 md:flex-col md:pt-16 lg:left-72`}
                >
                  <ProgramsSidebar />
                </div>
              </ProgramsSidebarContext.Provider>
            )}
          </div>

          <div className="flex h-full flex-col">
            <div className="flex grow">
              <main
                className={`mt-16 w-full bg-background md:ml-[18rem] ${
                  useProgramsSidebar && "lg:ml-[36rem]"
                }`}
              >
                <div className="overflow-hidden bg-background py-6">
                  <div className="mx-auto max-w-7xl bg-background px-4 sm:px-6 md:px-8">
                    {useProgramsSidebar ? (
                      <ProgramsSidebarContext.Provider
                        value={{
                          sidebarOpen: programsSidebarOpen,
                          setSidebarOpen: setProgramsSidebarOpen,
                        }}
                      >
                        {children}
                      </ProgramsSidebarContext.Provider>
                    ) : (
                      children
                    )}
                  </div>
                </div>
              </main>
            </div>
            <div
              className={`md:ml-[18rem] ${
                useProgramsSidebar && "lg:ml-[36rem]"
              }`}
            >
              <Footer />
            </div>
          </div>
        </div>
      </ProgramsFilterStateContext.Provider>
    </div>
  );
};

export default withOnboardingRequired<DashboardLayoutProps>(DashboardLayout);

const getFilterStateFromQuery = (query: string | null): string[] => {
  if (!query) {
    return [];
  }

  return query.split(",");
};
