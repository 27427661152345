import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";
import Filter from "~/components/filters/Filter";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import { useSetSelectedState } from "~/components/hooks/SelectedState";
import { MAX_DEADLINE_FILTER_CHOICES } from "~/lib/data/maxDeadline";
import { STATES_AND_TERRITORIES } from "~/lib/data/states";
import { getSelectChoiceArrayById } from "~/lib/utils/utils";
import { MultiselectCheckboxesWithAllOption } from "../../../filters/checkboxes/MultiselectCheckboxesWithAllOption";
import { useFilterStateContext } from "./ProgramsFilterStateContext";
import {
  FilterType,
  FilterTypeToIcon,
  MultiselectFilterType,
  multiselectFilterTypes,
  singleSelectFilterTypes,
} from "./shared";

interface ProgramsSidebarDisplayProps {
  goalChoices: SelectChoice[];
  fundingSourceChoices: SelectChoice[];
  demographicChoices: SelectChoice[];
  entityChoices: SelectChoice[];
}

export const ProgramsSidebarDisplay: FC<ProgramsSidebarDisplayProps> = ({
  goalChoices,
  fundingSourceChoices,
  demographicChoices,
  entityChoices,
}) => {
  const router = useRouter();

  const { filterState, onSelectionUpdated } = useFilterStateContext();

  const setSelectedState = useSetSelectedState();

  useEffect(() => {
    router.push(router.basePath, {
      query: getQueryParms(),
    });
    // Don't add router to this list, it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const filterTypeToValuesMap: Record<FilterType, SelectChoice[]> = {
    state: STATES_AND_TERRITORIES,
    maxDeadline: MAX_DEADLINE_FILTER_CHOICES,
    fundingSources: fundingSourceChoices,
    fundingGoals: goalChoices,
    demographics: demographicChoices,
    entity: entityChoices,
  };

  const filterTypeToTitleMap: Record<FilterType, string> = {
    state: "Location",
    maxDeadline: "Deadline",
    fundingSources: "Funding Sources",
    fundingGoals: "I Want Funding For",
    demographics: "Programs Specifically for",
    entity: "Entity Type",
  };

  const getQueryParms = (): Record<FilterType, string | undefined> => {
    if (filterState === undefined)
      return {} as Record<FilterType, string | undefined>;

    return Object.entries(filterState).reduce((acc, [filterType, choices]) => {
      return {
        ...acc,
        [filterType]: choices.map((choiceId) => choiceId).join(","),
      };
    }, {} as Record<FilterType, string | undefined>);
  };

  if (filterState === undefined) {
    return null;
  }

  const makeMultiselectFilterSection = (filterType: MultiselectFilterType) => {
    const Icon = FilterTypeToIcon[filterType];
    return (
      <>
        <div className="flex h-20 shrink-0 items-center gap-2 border-y  border-borderGrey bg-lightBlue px-6">
          <Icon className="h-5 w-5 fill-deepBlue" />
          <span className="text-lg font-semibold text-deepBlue">
            {filterTypeToTitleMap[filterType]}
          </span>
        </div>

        <div className="space-y-10 px-6 py-10">
          <div className="space-y-2">
            <MultiselectCheckboxesWithAllOption
              currentSelection={filterState[filterType]}
              multiselectChoices={filterTypeToValuesMap[filterType]}
              filterType={filterType}
              onSelectionUpdated={(selection) => {
                onSelectionUpdated(selection, filterType);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const makeSingleSelectFilterSection = (filterType: FilterType) => {
    return (
      <div className="space-y-2">
        <div className="font-semibold text-deepBlue">
          {filterTypeToTitleMap[filterType]}
        </div>
        <Filter
          filterText={`All ${filterTypeToTitleMap[filterType]}s`}
          choices={filterTypeToValuesMap[filterType]}
          selectedChoice={
            getSelectChoiceArrayById(
              filterState[filterType]?.[0],
              filterTypeToValuesMap[filterType]
            )?.[0]
          }
          onChoiceSelected={(choice) => {
            {
              if (filterType === "state" && choice) {
                // persist state choice
                setSelectedState.mutate({ state: choice?.id });
              }
              onSelectionUpdated(choice ? [choice.id] : [], filterType);
            }
          }}
          preventDeselect={filterType === "state"}
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex h-20 shrink-0 items-center gap-2 border-b border-borderGrey bg-lightBlue px-6">
        <AdjustmentsHorizontalIcon className="h-5 w-5 rotate-90 text-deepBlue" />
        <span className="text-lg font-semibold text-deepBlue">Filters</span>
      </div>
      {/* Filters section */}
      <div className="space-y-10 px-6 py-10">
        {singleSelectFilterTypes.map(makeSingleSelectFilterSection)}
      </div>
      {multiselectFilterTypes.map(makeMultiselectFilterSection)}
    </>
  );
};
