import { FC } from "react";
import { SelectChoice } from "~/components/forms/fields/SelectItem";

export interface StatefulSelectChoice extends SelectChoice {
  isSelected: boolean;
}

export const FilterCheckboxItem: FC<{
  choice: StatefulSelectChoice;
  onChange: (choice: SelectChoice) => void;
}> = ({ choice, onChange }) => {
  return (
    <div className="flex items-center gap-3">
      <input
        type="checkbox"
        id={choice.id}
        checked={choice.isSelected}
        onChange={() => onChange(choice)}
        className="h-5 w-5 cursor-pointer rounded-[3px] border-deepBlue text-deepBlue"
      />
      <label
        className="cursor-pointer select-none font-normal text-deepBlue"
        htmlFor={choice.id}
      >
        {choice.text}
      </label>
    </div>
  );
};
