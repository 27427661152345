import { FC } from "react";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import { FilterType } from "~/components/layout/dashboard/programs/shared";
import { FilterCheckboxGroup } from "./FilterCheckboxGroup";
import { getNewMultiselection } from "./getNewMultiSelection";

export interface MultiselectCheckboxesWithAllOptionProps {
  multiselectChoices: SelectChoice[];
  currentSelection: SelectChoice["id"][];
  onSelectionUpdated: (selection: SelectChoice["id"][]) => void;
  filterType: FilterType;
}

export const MultiselectCheckboxesWithAllOption: FC<
  MultiselectCheckboxesWithAllOptionProps
> = ({
  multiselectChoices,
  currentSelection,
  onSelectionUpdated,
  filterType,
}) => {
  const allChoice: SelectChoice = getAllChoice(filterType);

  const filterCheckboxGroupProps = {
    choices: [
      ...[
        {
          ...allChoice,
          isSelected: currentSelection.length < 1,
        },
      ],
      ...multiselectChoices.map((c) => ({
        ...c,
        isSelected: currentSelection.find((f) => f === c.id) !== undefined,
      })),
    ],
    onChange: (choice: SelectChoice) => {
      onSelectionUpdated(
        choice.id === allChoice.id
          ? []
          : getNewMultiselection(choice, currentSelection)
      );
    },
  };

  return <FilterCheckboxGroup {...filterCheckboxGroupProps} />;
};

export const getAllChoice = (filterType: FilterType): SelectChoice => ({
  id: `all-${filterType}`,
  text: "Show All",
});
