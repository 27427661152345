import produce from "immer";
import { createContext, useContext } from "react";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import { track } from "~/lib/analytics";
import { FilterStateType } from "../DashboardLayout";
import { FilterType } from "./shared";

export const ProgramsFilterStateContext = createContext<
  ProgramsFilterStateContextType | undefined
>(undefined);

export type ProgramsFilterStateContextType = {
  filterState: Record<FilterType, SelectChoice["id"][]> | undefined;
  setFilterState: (
    filterState: Record<FilterType, SelectChoice["id"][]>
  ) => void;
};

interface UseFilterStateProps extends ProgramsFilterStateContextType {
  onSelectionUpdated: (
    selection: SelectChoice["id"][],
    filterType: FilterType
  ) => void;
  clearSelections: (filterTypesToClear: FilterType[]) => void;
}

export const useFilterStateContext = (): UseFilterStateProps => {
  const context = useContext(ProgramsFilterStateContext);

  if (context === undefined) {
    throw Error(
      "ProgramsFilterStateContext is undefined but should be defined by the time it reaches this component"
    );
  }

  const onSelectionUpdated = (
    currentState: FilterStateType,
    selection: SelectChoice["id"][],
    filterType: FilterType
  ): FilterStateType => {
    const trackingObject = { ...currentState };
    trackingObject[filterType] = selection;
    track("Program Filter Toggled", trackingObject);
    return produce(currentState, (draft) => {
      draft[filterType] = selection;
    });
  };

  const clearSelections = (
    currentState: FilterStateType,
    typesToClear: FilterType[]
  ) => {
    const trackingObject = { ...currentState };
    typesToClear.forEach((filterType) => {
      trackingObject[filterType] = [];
    });
    track("Filter Tags Cleared", trackingObject);
    return produce(currentState, (draft) => {
      typesToClear.forEach((filterType) => {
        draft[filterType] = [];
      });
    });
  };

  return {
    ...context,
    onSelectionUpdated: (
      selection: SelectChoice["id"][],
      filterType: FilterType
    ) => {
      if (context.filterState === undefined) {
        throw Error(
          "Trying to call onChoiceSelected before the initial state is set"
        );
      }
      context.setFilterState(
        onSelectionUpdated(
          context.filterState,
          selection.map((s) => s),
          filterType
        )
      );
    },
    clearSelections: (filterTypesToClear: FilterType[]) => {
      if (context.filterState === undefined) {
        throw Error(
          "Trying to call clearSelections before the initial state is set"
        );
      }
      context.setFilterState(
        clearSelections(context.filterState, filterTypesToClear)
      );
    },
  };
};
