import React from "react";

import { SVGProps } from "../_svg";

export const ChevronDown: React.FC<SVGProps> = ({
  className = "",
  ...props
}) => (
  <svg
    className={`text-text -mr-1 ml-2 h-5 w-5 fill-current ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
      clipRule="evenodd"
    />
  </svg>
);
