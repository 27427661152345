import { FC, useState } from "react";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import { FilterCheckboxItem, StatefulSelectChoice } from "./FilterCheckboxItem";
import { ShowMoreButton } from "./ShowMoreButton";

export interface FilterCheckboxGroupProps {
  choices: StatefulSelectChoice[];
  onChange: (choice: SelectChoice) => void;
}

export const FilterCheckboxGroup: FC<FilterCheckboxGroupProps> = ({
  choices,
  onChange,
}) => {
  const [showFull, setShowFull] = useState<boolean>(false);

  const longList = choices.length > 10;

  return (
    <div className="space-y-2.5">
      <div className="divide-y-borderGrey flex flex-col gap-4 divide-y-2">
        <div className="relative space-y-2.5 ">
          <>
            {!showFull && longList ? (
              <>
                {choices.slice(0, 8).map((choice) => (
                  <FilterCheckboxItem
                    key={choice.id}
                    choice={choice}
                    onChange={onChange}
                  />
                ))}
                {!showFull && (
                  <div className="absolute bottom-0 z-20 h-6 w-full bg-gradient-to-b from-transparent to-[#ECF1F5]" />
                )}
              </>
            ) : (
              choices.map((choice) => (
                <FilterCheckboxItem
                  key={choice.id}
                  choice={choice}
                  onChange={onChange}
                />
              ))
            )}
          </>
        </div>
        {longList && (
          <div className="pt-4">
            <ShowMoreButton {...{ showFull, setShowFull }} />
          </div>
        )}
      </div>
    </div>
  );
};
