import { Demographic } from "~/components/assets/icons/programTags/demographic";
import { FundingSource } from "~/components/assets/icons/programTags/funding-source";
import { Goals } from "~/components/assets/icons/programTags/goals";

export type MultiselectFilterType =
  | "fundingSources"
  | "fundingGoals"
  | "demographics";

export type SingleSelectFilterType = "state" | "entity" | "maxDeadline";

export type FilterType = MultiselectFilterType | SingleSelectFilterType;

export const multiselectFilterTypes: MultiselectFilterType[] = [
  "fundingGoals",
  "fundingSources",
  "demographics",
];

export const singleSelectFilterTypes: SingleSelectFilterType[] = [
  "state",
  "entity",
  "maxDeadline",
];

export const allProgramFilterTypes: FilterType[] = [
  ...multiselectFilterTypes,
  ...singleSelectFilterTypes,
];

export const FilterTypeToIcon: Record<
  MultiselectFilterType,
  (props: React.ComponentProps<"svg">) => JSX.Element
> = {
  fundingSources: FundingSource,
  fundingGoals: Goals,
  // fundingTypes: FundingType,
  demographics: Demographic,
};
