import { SelectChoice } from "~/components/forms/fields/SelectItem";

export const ENTITY_TYPES: SelectChoice[] = [
  {
    id: "GP",
    text: "General Partnership",
  },
  {
    id: "JV",
    text: "Joint Venture",
  },
  {
    id: "SP",
    text: "Individual/Sole Proprietorship",
  },
  {
    id: "CORP",
    text: "Corporation",
  },
  {
    id: "LP",
    text: "Limited Partnership",
  },
  {
    id: "LLC",
    text: "Limited Liability Company",
  },
  {
    id: "RT",
    text: "Revocable/Living Trust",
  },
  {
    id: "IT",
    text: "Irrevocable Trust",
  },
  {
    id: "EST",
    text: "Estate",
  },
  {
    id: "CTO",
    text: "Charitable/Tax-exempt Organization",
  },
  {
    id: "PS",
    text: "Public School",
  },
  {
    id: "CCSE",
    text: "City, County or State-owned Entity",
  },
  {
    id: "IND",
    text: "Indian Tribe",
  },
];
