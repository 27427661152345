import { SelectChoice } from "~/components/forms/fields/SelectItem";

// Used to manage what happens when a filter checkbox gets selected
export const getNewMultiselection = (
  toggledChoice: SelectChoice,
  currentSelection: SelectChoice["id"][]
): SelectChoice["id"][] => {
  const newChoiceIndex = currentSelection.findIndex(
    (c) => c === toggledChoice.id
  );

  // If found in array, remove from array
  if (newChoiceIndex >= 0) {
    return [
      ...(newChoiceIndex > 0 ? currentSelection.slice(0, newChoiceIndex) : []),
      ...currentSelection.slice(newChoiceIndex + 1),
    ];
  }

  // Otherwise, add to array
  else {
    return [...currentSelection, toggledChoice.id];
  }
};
