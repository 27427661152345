import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { SelectChoice } from "~/components/forms/fields/SelectItem";
import { DataResponse } from "~/lib/core/DataResponse";

export interface TagResponse {
  values: Tag[];
}

export interface Tag {
  type: TagType;
  values: SelectChoice[];
}

export type TagType = "DEMOGRAPHIC" | "GOAL" | "SOURCE" | "ENTITY";
// | "FUNDING_TYPE";

export const useTags = () =>
  useQuery<DataResponse<TagResponse>, AxiosError>(
    ["tags"],
    async () => {
      return (
        await axios.get<DataResponse<TagResponse>>(
          "/api/proxy/v3/programs/tags"
        )
      ).data;
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  );
