import { SelectChoice } from "~/components/forms/fields/SelectItem";

export const STATES_AND_TERRITORIES: SelectChoice<string>[] = [
  {
    id: "AL",
    text: "Alabama",
  },
  {
    id: "AK",
    text: "Alaska",
  },
  {
    id: "AS",
    text: "American Samoa",
  },
  {
    id: "AZ",
    text: "Arizona",
  },
  {
    id: "AR",
    text: "Arkansas",
  },
  {
    id: "CA",
    text: "California",
  },
  {
    id: "CO",
    text: "Colorado",
  },
  {
    id: "CT",
    text: "Connecticut",
  },
  {
    id: "DE",
    text: "Delaware",
  },
  {
    id: "DC",
    text: "District Of Columbia",
  },
  {
    id: "FM",
    text: "Federated States Of Micronesia",
  },
  {
    id: "FL",
    text: "Florida",
  },
  {
    id: "GA",
    text: "Georgia",
  },
  {
    id: "GU",
    text: "Guam",
  },
  {
    id: "HI",
    text: "Hawaii",
  },
  {
    id: "ID",
    text: "Idaho",
  },
  {
    id: "IL",
    text: "Illinois",
  },
  {
    id: "IN",
    text: "Indiana",
  },
  {
    id: "IA",
    text: "Iowa",
  },
  {
    id: "KS",
    text: "Kansas",
  },
  {
    id: "KY",
    text: "Kentucky",
  },
  {
    id: "LA",
    text: "Louisiana",
  },
  {
    id: "ME",
    text: "Maine",
  },
  {
    id: "MD",
    text: "Maryland",
  },
  {
    id: "MA",
    text: "Massachusetts",
  },
  {
    id: "MI",
    text: "Michigan",
  },
  {
    id: "MN",
    text: "Minnesota",
  },
  {
    id: "MS",
    text: "Mississippi",
  },
  {
    id: "MO",
    text: "Missouri",
  },
  {
    id: "MT",
    text: "Montana",
  },
  {
    id: "NE",
    text: "Nebraska",
  },
  {
    id: "NV",
    text: "Nevada",
  },
  {
    id: "NH",
    text: "New Hampshire",
  },
  {
    id: "NJ",
    text: "New Jersey",
  },
  {
    id: "NM",
    text: "New Mexico",
  },
  {
    id: "NY",
    text: "New York",
  },
  {
    id: "NC",
    text: "North Carolina",
  },
  {
    id: "ND",
    text: "North Dakota",
  },
  {
    id: "MP",
    text: "Northern Mariana Islands",
  },
  {
    id: "OH",
    text: "Ohio",
  },
  {
    id: "OK",
    text: "Oklahoma",
  },
  {
    id: "OR",
    text: "Oregon",
  },
  {
    id: "PA",
    text: "Pennsylvania",
  },
  {
    id: "PR",
    text: "Puerto Rico",
  },
  {
    id: "RI",
    text: "Rhode Island",
  },
  {
    id: "SC",
    text: "South Carolina",
  },
  {
    id: "SD",
    text: "South Dakota",
  },
  {
    id: "TN",
    text: "Tennessee",
  },
  {
    id: "TX",
    text: "Texas",
  },
  {
    id: "UT",
    text: "Utah",
  },
  {
    id: "VT",
    text: "Vermont",
  },
  {
    id: "VI",
    text: "Virgin Islands",
  },
  {
    id: "VA",
    text: "Virginia",
  },
  {
    id: "WA",
    text: "Washington",
  },
  {
    id: "WV",
    text: "West Virginia",
  },
  {
    id: "WI",
    text: "Wisconsin",
  },
  {
    id: "WY",
    text: "Wyoming",
  },
];

export function getStateSelectChoices(
  stateCodes: string[]
): SelectChoice<string>[] {
  return STATES_AND_TERRITORIES.filter(({ id }) => stateCodes.includes(id));
}
