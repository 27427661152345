import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

export const ShowMoreButton: FC<{
  showFull: boolean;
  setShowFull: (choice: boolean) => void;
}> = ({ showFull, setShowFull }) => {
  return (
    <div
      className="flex cursor-pointer items-center gap-2"
      onClick={() => setShowFull(!showFull)}
    >
      {!showFull ? (
        <>
          <ChevronDownIcon className="h-6 w-6 text-deepBlue" />
          <span className="cursor-pointer text-deepBlue underline">
            Show more
          </span>
        </>
      ) : (
        <>
          <ChevronUpIcon className="h-6 w-6 text-deepBlue" />
          <span className="cursor-pointer text-deepBlue underline">
            Show less
          </span>
        </>
      )}
    </div>
  );
};
