import { useMutation, useQuery, useQueryClient } from "react-query";

interface SetSelectedStateArgs {
  state: string;
}

const queryKey = ["state", "selected"];

export const storageKey = "selectedState";

export function useSetSelectedState() {
  const queryClient = useQueryClient();
  return useMutation<string, Error, SetSelectedStateArgs>(
    async ({ state }) => {
      window.localStorage.setItem(storageKey, state);
      return state;
    },
    {
      onSuccess() {
        queryClient.refetchQueries(queryKey);
      },
    }
  );
}

export function useGetSelectedState() {
  return useQuery<string | undefined>(queryKey, () => {
    return window.localStorage.getItem(storageKey) || undefined;
  });
}
