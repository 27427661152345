import { FC } from "react";
import { Spinner } from "~/components/assets/icons/spinner";
import WithLeftIcon from "../../WithLeftIcon";
import { ProgramsSidebarDisplay } from "./ProgramsSidebarDisplay";
import { TagType, useTags } from "./useTags";

interface ProgramsSidebarProps {}

export const ProgramsSidebar: FC<ProgramsSidebarProps> = () => {
  const tags = useTags();

  const extractTagChoices = (tagType: TagType) =>
    tags.data?.data?.values.find((tag) => tag.type === tagType)?.values ?? [];

  return (
    <div
      className="flex min-h-screen flex-grow flex-col overflow-y-auto border-r border-borderGrey bg-lighterBlue pb-20"
      id="programs-sidebar"
    >
      {tags.isLoading ? (
        <div className="m-auto self-center rounded-md bg-lightBlue p-4 text-deepBlue sm:mt-20">
          <WithLeftIcon
            icon={<Spinner className="mr-2 h-4 w-4 animate-spin" />}
          >
            Loading filters...
          </WithLeftIcon>
        </div>
      ) : (
        <ProgramsSidebarDisplay
          goalChoices={extractTagChoices("GOAL").sort((a, b) =>
            a.text.localeCompare(b.text)
          )}
          fundingSourceChoices={extractTagChoices("SOURCE")}
          demographicChoices={extractTagChoices("DEMOGRAPHIC")}
          entityChoices={extractTagChoices("ENTITY")}
        />
      )}
    </div>
  );
};
