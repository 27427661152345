import { SelectChoice } from "~/components/forms/fields/SelectItem";

export const MAX_DEADLINE_FILTER_CHOICES: SelectChoice[] = [
  {
    id: "ONGOING",
    text: "Ongoing",
  },
  {
    id: "LESSTHAN1WEEK",
    text: "Less than 1 week",
  },
  {
    id: "LESSTHAN1MONTH",
    text: "Less than 1 month",
  },
  {
    id: "LESSTHAN3MONTHS",
    text: "Less than 3 months",
  },
];
